@import '~org/styles/variables';

.mn_searchTriggerWrapper {
  position: relative;
  margin-right: 1rem;

  button {
    font-size: 2rem;
    color: #fff;
    padding: 0;
    background: none;
    border: none;
    display: block;
    height: 2rem;
  }
}
