@import '~org/styles/variables';

.mn_pageHeader {
  position: relative;
  z-index: 3;
  background: #304cb2;

  .mn_searchBar {
    flex: 1;
    position: relative;
    z-index: 1;
  }

  .mn_headerInner {
    transform: translateZ(0);
    transition: opacity .5s ease-in-out;

    &.mn_contentWrap {
      @media #{$less-than-medium-screen} {
        padding: 0 1rem;
      }
    }
  }

  .mn_headerMainBar {
    position: relative;
    z-index: 3;
  }

  .mn_brandLinks {
    margin: 0 -1rem;
    padding: .5rem 0 2.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .mn_button {
      width: 14rem;
      height: 3rem;
      line-height: 3rem;
      padding: 0;
      font-size: 1.4rem;
    }
  }

  // Sticky
  &.mn_sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    transform: translateZ(0);

    .mn_headerInner {
      opacity: 1;
      box-shadow: 0 3px 6px 0 #304cb2;
    }

    .mn_headerMainBar {
      transition: .2s padding ease-in-out, .2s background ease-in-out;

      .mn_brandLogo a {
        &:before,
        &:after {
          transition: all .5s ease-in-out;
        }
      }
    }
  }

  @media #{$less-than-xlarge-screen} {
    .mn_searchInput {
      background: #1a2c80;
    }
  }

  @media #{$less-than-medium-screen} {
    &.mn_isAnonymous {
      .mn_quickSearch .mn_quickSearchResults {
        overflow: hidden;
        background: #f5f5f7;
      }
    }
  }
}

.mn_pageHeaderOffset {
  overflow: hidden;
  visibility: hidden;
  flex: 0 0 0;
}
