@import '~org/styles/variables';

.mn_brandFooter {
  text-align: left;
  padding: 2rem 0 0 0;

  .mn_brandFooterInner {
    display: flex;
  }

  .mn_footerNav {
    flex-basis: 35%;
    margin: 0 4% 0 0;

    h3 {
      color: #4c4c4e;
      margin: 0 0 1rem 0;
    }

    ul {
      margin: 0 0 4rem;
      padding: 0;
      list-style: none;

      li {
        margin: 0 0 1rem 0;
        padding: 0;

        a {
          font-weight: bold;
          font-size: 1.4rem;
        }
      }
    }
  }

  .mn_footerRightSide {
    display: flex;
    flex-direction: column;
  }

  .mn_backToTopButtonWrap {
    background: #111b40;
    padding: 5rem 0;
    text-align: center;

    .mn_backToTopButton {
      color: #fff;
      font-weight: 700;
      font-size: 1.6rem;
      background: none;
      border: none;
    }
  }

  @media #{$medium-screen} {
    background: #f5f5f5;
    padding: 5rem 0;
  }
}

.mn_VHP .mn_brandFooter {
  @media #{$less-than-medium-screen} {
    padding: 0;
  }
}

.mn_MHP .mn_brandFooter {
  @media #{$less-than-medium-screen} {
    padding: 2rem 0 10rem 0;
  }
}
