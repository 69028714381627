@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_mainNavigationTOF {
  width: 27.5rem;

  /* Navigation drop down */
  .mn_navigationDropdownWrapper {
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: -2rem;

    .mn_navigationDropdown {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transform: translate3d(0, -10px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 4.5rem;
      left: 0;
      width: 98rem;
      color: #111b40;
      padding: 1px 0 0;
      z-index: 2;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      background: #fff;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .4);

      &:after {
        content: '';
        height: .7px;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: radial-gradient(circle at center, rgba(212, 212, 208, .5) 0, rgba(212, 212, 208, .5) 100px, rgba(212, 212, 208, 0) 1000px);
      }
    }

    .mn_navigationDropdownTrigger {
      display: block;
      background: none;
      border: none;
      color: #fff;
      font-size: 1.5rem;
      line-height: 2.4rem;
      height: 2.8rem;
      padding: 0 .5rem 0 2.5rem;
      text-transform: uppercase;
      text-align: left;
      font-weight: 700;

      &:hover {
        outline: none;
        box-shadow: 0 2px 1px -1px #e9e9e9;
      }

      .mn_navArrow {
        position: absolute;
        top: 0;
        left: 0;

        &:before {
          display: inline-block;
          content: '';
          background: no-repeat center/100% url('~org/assets/images/icons/carrot_down.svg');
          width: 1.9rem;
          height: 1.9rem;
          position: relative;
          top: .4rem;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        pointer-events: all;
        visibility: visible;
      }

      .mn_navigationDropdownTrigger {
        .mn_navArrow:before {
          background: no-repeat center/100% url('~org/assets/images/icons/carrot_up.svg');
        }
      }
    }
  }

  /* Common navigation lists styles */
  h2 {
    font-size: 2rem;
    color: #111b40;
    font-weight: bold;
    margin: 0 0 3.5rem;
  }

  .mn_navDropdownMenu {
    width: auto;
    flex: 0 0 auto;
    margin: 0 -1rem;
    padding: 0;
    border: none;
    list-style: none;

    li {
      margin: 0;

      & > a {
        display: block;
        margin-left: -2rem;
        padding: .9rem 1rem .8rem 3rem;
        color: $brandTertiaryColor;
        text-shadow: 0 1px rgba(0, 0, 0, .2);
        letter-spacing: .29px;
        font-size: 1.4rem;
      }
    }
  }

  .mn_middleMainNavPanelSect {
    flex: 0 0 36rem;
    padding: 2rem 3.5rem 0;
    max-width: 36.1rem;
    border-right: rgba(212, 212, 208, .5) solid 1px;
    margin-bottom: 26rem;

    .mn_navDropdownMenu li > a {
      position: relative;
    }

    .mn_navOffersList .mn_extraRewardsPill {
      left: unset;
      transform: none;
      margin-left: 5px;
    }
  }

  .mn_leftMainNavPanelSect {
    flex: 0 0 27.5rem;
    max-width: 27.5rem;
    padding: 2rem 1rem 5rem 3rem;
    border-right: #ccc solid 1px;

    .mn_navCategoriesTOF > a:hover {
      background: #f5f7f7;
    }
  }

  .mn_rightMainNavPanelSect {
    flex: 0 0 36rem;
    max-width: 36rem;
    padding: 2rem 3.5rem 0;
    margin-bottom: 26rem;
  }

  .mn_bottomMainNavPanelSect {
    width: 53rem;
    position: absolute;
    bottom: 10rem;
    left: 31rem;
  }
}
