@import 'variables';
@import 'icons';
@import 'mixins';
@import 'interstitial';
@import '~core/styles/responsive-utilities';
@import '~core/styles/grid';
@import '~core/styles/mixins';
@import '~core/styles/defaults';

/* Fonts */
@import 'fonts';
@import '~core/fonts/fontello/css/fontello';
@import '~org/fonts/swa-categories/css/swa_categories';

sup {
  display: inline-block;
  vertical-align: top;
  font-size: 70%;
  margin-top: -.5rem;
}

html {
  width: 100%;

  &.mn_isIphoneSafari {
    overflow-y: initial;
  }

  &.mn_open {
    @media #{$less-than-medium-screen} {
      overflow-y: hidden;
      position: fixed; // Fix overscroll and breaking the menu on iPhone
    }
  }
}

.mn_pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.mn_navSeasonalFlyoutSection .mn_link,
.mn_navPopularStoresSection .mn_viewAllPopularStoresLink,
.mn_navCategoryFeaturedFooter .mn_seeAllLink,
.mn_recentStores .mn_viewAllRecentStoresLinkBottom,
.mn_headerExtraRewards .mn_linkBottom,
.mn_pageHeader .mn_productLink,
.mn_howItWorks .mn_howItWorksVideoLink {
  @include mn_linkWithArrow();
}

.mn_searchModal .mn_searchForm .mn_productLink {
  @include mn_linkWithArrow(.8rem);
}

.mn_letterboxTOF {
  @media #{$less-than-medium-screen} {
    .mn_additionalElevationPrefix {
      display: none;
    }
  }
}

html.mn_VHP {
  .mn_howItWorksWrap {
    background: #f5f5f5;
    margin-top: 3rem;
  }

  @media #{$medium-screen} {
    .mn_featuredOffersLogo {
      .mn_sectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .mn_viewAllLink {
          font-size: 1.6rem;
        }
      }
    }
  }
}

html.mn_MHP {
  .mn_testimonials + .mn_trendingAndSeasonalOffers,
  .mn_exploreCategories + .mn_trendingAndSeasonalOffers,
  .mn_earningsSnapshot + .mn_trendingAndSeasonalOffers,
  .mn_emailOptInWrapper + .mn_trendingAndSeasonalOffers {
    margin-top: 5rem;
  }
}
