@import '~core/styles/mixins';
@import 'variables';

@mixin mn_linkWithArrow($fontSize: 1.2rem, $margin: 0 0 0 .8rem) {
  &:after {
    content: '\e005';
    font-family: 'swa-icon', serif;
    font-size: $fontSize;
    vertical-align: middle;
    display: inline-block;
    margin: $margin;
  }
}
