@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_headerMainBar {
  padding: 1rem 0;

  .mn_contentSection {
    position: relative;
    max-width: $xxlarge;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .mn_searchAndNavWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mn_headerTopNavigation {
    display: flex;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 2rem 0 10%;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      border-right: .75px solid rgba(212, 212, 208, .5);
      top: -.2rem;
      bottom: -.2rem;
      right: -1.5rem;
    }

    .mn_searchTrigger {
      outline: none;

      &[aria-expanded='true'] {
        &:before {
          content: '\e90c';
        }
      }
    }
  }

  @media #{$xlarge-screen} {
    padding: 1.5rem 0;

    .mn_contentSection {
      display: block;
      justify-content: space-between;
    }

    .mn_searchAndNavWrap {
      width: auto;
      margin: 2rem 0 0 0;
    }

    .mn_headerTopNavigation:after {
      display: none;
    }
  }
}
