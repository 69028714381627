@import '~org/styles/variables';

.mn_mobileNavWelcomeMessage {
  padding: 3rem 2rem 2.5rem 2rem;
  color: #fff;
  background: #1a2c80;

  &.mn_nonIdentified {
    padding: 3rem 2rem 2rem 2rem;
  }

  .mn_logoutLink {
    display: block;
    margin-top: 1rem;
    font-size: 1.8rem;
    color: #fff;
  }

  h3 {
    color: #fff;
    margin: 0;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 400;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.3rem;

    a {
      color: #fff;
    }
  }
}
