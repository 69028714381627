@import '~org/styles/variables';

.mn_topNavigationWrap {
  color: #fff;
  position: absolute;
  top: 1rem;
  right: 0;

  .mn_topNavigation {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      font-size: 1.4rem;
      color: #fff;
      margin: 0 0 0 1rem;
      display: inline-block;

      a {
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
