@import '~org/styles/variables';

.mn_accountNavigation {
  color: #fff;

  /* Account Navigation elements */
  .mn_navigationWrap {
    display: flex;
    align-items: center;
    margin: 0 -1rem 0 0;
    padding: 0;
    list-style: none;

    & > li {
      text-align: center;
      position: relative;
      margin: 0;

      &:before {
        content: '';
        width: 1px;
        position: absolute;
        left: 0;
        height: 30px;
        background: rgba(255, 255, 255, .3);
      }
    }

    .mn_navigationItem {
      display: inline-block;
      align-items: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.5rem;
      padding: 0 .5rem .5rem;
      text-decoration: none;
      color: #fff;
      text-align: center;
      background: none;
      border: none;

      & > i {
        display: inline-block;
        width: 20px;
        height: 22px;
        line-height: 20px;
        font-size: 1.8rem;
        margin: 0 1rem 0 0;
        position: relative;
        top: 4px;
      }
    }

    a.mn_navigationItem:hover {
      outline: none;
      box-shadow: 0 2px 1px -1px #e9e9e9;
    }

    .mn_searchSection {
      margin-left: 1rem;
      padding-left: 1rem;

      .mn_searchTrigger {
        cursor: pointer;
      }
    }
  }

  /* Dropdown */
  .mn_navigationDropdownTrigger {
    display: inline-block;
    position: relative;
    margin: 0 -.5rem -1.5rem;
    padding: 0 .5rem 1.5rem;

    .mn_navigationItem {
      position: relative;
      z-index: 2;

      &:hover {
        outline: none;
        box-shadow: 0 2px 1px -1px #e9e9e9;
      }
    }

    .mn_navigationDropdown {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-25%, -28px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 100%;
      right: -18.5rem;
      width: 37rem;
      z-index: 2;
      pointer-events: none;
      text-align: left;
      background: #fff;
      color: #000;
      box-shadow: 0 3px 5px 0 rgba(100, 100, 100, .3);

      &:before {
        content: '';
        left: 50%;
        margin: -8px 0 0 0;
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        top: 0;
        box-sizing: border-box;
        border: 1rem solid #fff;
        border-color: transparent transparent #fff #fff;
        transform: rotate(135deg);
        box-shadow: -3px 2px 2px 0 rgba(0, 0, 0, .05);
        background: #fff;
        z-index: -1;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;
          font-size: 1.6rem;
          line-height: 2.4rem;
          border-bottom: 1px solid #e9e9e9;
        }

        a {
          color: #111b40;
          padding: 2rem 2.5rem;
          display: block;

          &:hover {
            background: #f5f7f7;
          }
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(-25%, 0, 0);
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  /* User section */
  .mn_userSection {
    &:before {
      display: none;
    }

    .mn_userInfo {
      margin-right: .5rem;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: right;

      .mn_userNameWrap {
        display: block;
        white-space: nowrap;
        font-weight: bold;

        .mn_userName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 17rem;
          vertical-align: bottom;
        }
      }
    }

    .mn_navigationDropdown {
      right: -9rem;

      &:before {
        left: 80%;
        border: 1rem solid #1a2a80;
        border-color: transparent transparent #1a2a80 #1a2a80;
        background: #1a2a80;
      }

      .mn_highlitedSection {
        background: #1a2a80;
        color: #fff;
        padding: 2rem 2.5rem;
        font-size: 1.6rem;

        .mn_userNameWrap {
          font-weight: bold;
        }

        .mn_userId {
          margin: 1rem 0 0 0;
        }
      }
    }

    .mn_button {
      font-size: 1.5rem;
      line-height: 2rem;
      padding: .5rem;
      width: 13rem;
    }
  }

  @media #{$xlarge-screen} {
    flex-basis: calc(100% - 55rem);
    margin-right: -1rem;

    .mn_userSection {
      flex-basis: 23%;

      &:before {
        display: block;
      }
    }

    .mn_navigationWrap {
      width: 100%;
      justify-content: space-between;
    }

    /* Extra Rewards Dropdown section */
    .mn_headerExtraRewardsSection {
      flex-basis: 28%;

      .mn_navigationDropdown {
        width: 38.5rem;
      }
    }

    .mn_helpSection {
      flex-basis: 27%;
    }

    .mn_favoritesSection {
      flex-basis: 22%;
    }
  }
}

/* Secondary navigation search bar for tablet and small desktop */
.mn_secondaryNavSearchBar {
  display: block;
  position: absolute;
  right: -2.7rem;
  top: 5rem;

  &:before {
    content: '';
    background: #304cb2;
    left: -3rem;
    right: -3rem;
    top: -2rem;
    height: 11rem;
    display: block;
    position: absolute;
    z-index: -1;
  }

  .mn_searchBar {
    border-bottom: 1px solid #fff;
    width: 34rem;
    padding: 0;

    &:before {
      display: none;
    }

    .mn_searchForm {
      .mn_dropDownWrap {
        left: -3rem;
        right: -3rem;
        top: 4rem;
      }

      .mn_searchInput {
        font-size: 1.8rem;
        height: 3rem;
        padding: .4rem 1.5rem .4rem 4rem;
        display: block;
      }

      .mn_searchSubmit {
        position: absolute;
        top: .5rem;
        right: 0;
        height: 2.4rem;
        line-height: 2.4rem;
        font-size: 1.6rem;
        width: 2rem;
        display: block;
      }
    }
  }
}
