@import '~org/styles/variables';

.mn_siteDisclaimer {
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: auto 0 4rem 0;

  @media #{$medium-screen} {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

.mn_VHP .mn_siteDisclaimer {
  @media #{$less-than-medium-screen} {
    margin-bottom: 2rem;
  }
}
