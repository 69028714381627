@import '~org/styles/variables';

.mn_brandLogo {
  display: block;
  width: 215px;
  height: 3rem;
  margin: 0 auto 0 0;

  a {
    display: inline-block;
    background: url('~org/assets/images/logo_white.svg') no-repeat no-repeat;
    background-size: contain;
    background-position: center left;
    width: 100%;
    height: 100%;

    span {
      font-size: 0;
      line-height: 0;
      opacity: 0;
    }
  }

  @media #{$medium-screen} {
    width: 27.5rem;
    height: 3.5rem;
  }
}
