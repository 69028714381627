@font-face {
  font-family: 'swa-icon';
  src: url('../fonts/swa-icon/swa-icon.eot');
  src:
    url('../fonts/swa-icon/swa-icon.eot#iefix') format('embedded-opentype'),
    url('../fonts/swa-icon/swa-icon.woff') format('woff'),
    url('../fonts/swa-icon/swa-icon.ttf') format('truetype'),
    url('../fonts/swa-icon/swa-icon.svg#swa-icon') format('svg');
  font-weight: bold;
  font-style: normal;
}
