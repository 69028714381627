[class*='mn_SVGIcon'] {
  background: none;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;

  &:before {
    content: '';
    display: inline-block;
    width: 2rem;
    height: 2.1rem;
  }
}

.mn_SVGIconSearch {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/search.svg);
  }

  &[aria-expanded='true'] {
    &:before {
      background: no-repeat center/100% url(../assets/images/icons/close_white.svg);
    }
  }
}

.mn_SVGIconClose {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/close_white.svg);
  }
}

.mn_SVGIconExtraRewards {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/extra.svg);
  }
}

.mn_SVGIconExtraRewardsBlue {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/extra_blue.svg);
  }
}

.mn_SVGIconFavorites {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/favorite.svg);
  }
}

.mn_SVGIconAccount {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/account.svg);
  }
}

.mn_SVGIconInfo {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/info.svg);
  }
}

.mn_SVGIconHamburgerMenu {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/hamburger_menu.svg);
  }
}

.mn_SVGIconNavigationClosed {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/carrot_down_dark.svg);
    width: 2.8rem;
    height: 1.6rem;
  }
}

.mn_SVGIconCheck {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/check_circle.svg);
    width: 4rem;
    height: 4rem;
  }
}

.mn_focused .mn_SVGIconNavigationClosed {
  &:before {
    background: no-repeat center/100% url(../assets/images/icons/carrot_up_dark.svg);
  }
}

html.mn_open {
  .mn_SVGIconHamburgerMenu {
    &:before {
      background: no-repeat center/100% url(../assets/images/icons/close_white.svg);
    }
  }
}

.swa-icon,
[class*='swa-icon_'] {
  font-family: swa-icon, sans-serif;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: .3px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.swa-icon_airplane:before {
  content: '\E001';
}

.swa-icon_airplane2:before {
  content: '\E002';
}

.swa-icon_arrow-down-solid:before {
  content: '\E003';
}

.swa-icon_arrow-left:before {
  content: '\E004';
}

.swa-icon_arrow-right:before {
  content: '\E005';
}

.swa-icon_arrow-up-solid:before {
  content: '\E006';
}

.swa-icon_zoom-in:before {
  content: '\E007';
}

.swa-icon_zoom-out:before {
  content: '\E008';
}

.swa-icon_calendar:before {
  content: '\E009';
}

.swa-icon_car:before {
  content: '\E00A';
}

.swa-icon_at-circle:before {
  content: '\E00B';
}

.swa-icon_cart:before {
  content: '\E00C';
}

.swa-icon_change-flight:before {
  content: '\E00D';
}

.swa-icon_check-in:before {
  content: '\E00E';
}

.swa-icon_check:before {
  content: '\E00F';
}

.swa-icon_close-thin:before {
  content: '\E010';
}

.swa-icon_close:before {
  content: '\E011';
}

.swa-icon_credit-card:before {
  content: '\E012';
}

.swa-icon_dining:before {
  content: '\E013';
}

.swa-icon_drink:before {
  content: '\E014';
}

.swa-icon_early-bird:before {
  content: '\E015';
}

.swa-icon_error:before {
  content: '\E016';
}

.swa-icon_external-link:before {
  content: '\E017';
}

.swa-icon_facebook:before {
  content: '\E018';
}

.swa-icon_clock:before {
  content: '\E019';
}

.swa-icon_global:before {
  content: '\E01A';
}

.swa-icon_google:before {
  content: '\E01B';
}

.swa-icon_grid:before {
  content: '\E01C';
}

.swa-icon_hotel:before {
  content: '\E01D';
}

.swa-icon_info:before {
  content: '\E01E';
}

.swa-icon_list-horizontal:before {
  content: '\E01F';
}

.swa-icon_list-vertical:before {
  content: '\E020';
}

.swa-icon_location:before {
  content: '\E021';
}

.swa-icon_map-search:before {
  content: '\E022';
}

.swa-icon_check-circle:before {
  content: '\E023';
}

.swa-icon_passenger:before {
  content: '\E024';
}

.swa-icon_phone:before {
  content: '\E025';
}

.swa-icon_points-dial:before {
  content: '\E026';
}

.swa-icon_key:before {
  content: '\E027';
}

.swa-icon_retail:before {
  content: '\E028';
}

.swa-icon_search:before {
  content: '\E029';
}

.swa-icon_share:before {
  content: '\E02A';
}

.swa-icon_special-offers:before {
  content: '\E02B';
}

.swa-icon_transfer:before {
  content: '\E02C';
}

.swa-icon_twitter:before {
  content: '\E02D';
}

.swa-icon_vacations:before {
  content: '\E02E';
}

.swa-icon_where-we-fly:before {
  content: '\E02F';
}

.swa-icon_wifi:before {
  content: '\E030';
}

.swa-icon_instagram:before {
  content: '\E031';
}

.swa-icon_youtube:before {
  content: '\E032';
}

.swa-icon_change-planes:before {
  content: '\E033';
}

.swa-icon_car-size:before {
  content: '\E034';
}

.swa-icon_at:before {
  content: '\E035';
}

.swa-icon_travel-time:before {
  content: '\E036';
}

.swa-icon_alert:before {
  content: '\E037';
}

.swa-icon_print:before {
  content: '\E038';
}

.swa-icon_stops:before {
  content: '\E039';
}

.swa-icon_security:before {
  content: '\E03A';
}

.swa-icon_early-bird-circle:before {
  content: '\E03B';
}

.swa-icon_email:before {
  content: '\E03C';
}

.swa-icon_minus-circle:before {
  content: '\E03D';
}

.swa-icon_plus-circle:before {
  content: '\E03E';
}

.swa-icon_arrow-down:before {
  content: '\E03F';
}

.swa-icon_departing:before {
  content: '\E040';
}

.swa-icon_returning:before {
  content: '\E041';
}

.swa-icon_overnight:before {
  content: '\E042';
}

.swa-icon_more-circle:before {
  content: '\E043';
}

.swa-icon_arrow-up:before {
  content: '\E044';
}

.swa-icon.swa-icon_no-before:before {
  content: '';
}
