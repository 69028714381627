@import '~org/styles/variables';

.mn_firstFavoriteModal {
  .mn_modal {
    padding: 4rem 3rem;
  }

  .mn_favoritesRequestProgressWrap {
    text-align: center;

    .mn_favoritesRequestProgress {
      display: inline-block;
      width: 12rem;
    }
  }

  h2 {
    color: #333;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.2rem;
    margin: 0 0 1rem;
  }

  p {
    color: #333;
    font-size: 1.6rem;
    line-height: 2.1rem;
    margin: 3rem 0;
  }

  .mn_favoriteTurnOnAlerts,
  .mn_viewFavorites {
    width: auto;
    font-size: 1.4rem;
    padding: .8rem 2.5rem;
    color: $brandNeutralDarkColor;
    border: none;
    line-height: 1;
  }

  .mn_favoriteNoThanks {
    display: block;
    padding: 3rem 0 0;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $brandPrimaryColor;
    cursor: pointer;
    background: none;
    border: none;
    text-align: left;
  }

  .mn_myAccountLink {
    color: $brandPrimaryColor;
  }

  @media #{$medium-screen} {
    .mn_modal {
      width: 64rem;
      border-radius: .7rem;
    }
  }
}
